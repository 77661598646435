import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import NextLink from 'next/link';
import {
  ElementType,
  ReactNode,
  ReactPropTypes,
  useState
} from 'react';
import { UrlObject } from 'url';

// ----------------------------------------------------------------------

export default function Link({
  path = '',
  target = '_self',
  underline = 'hover',
  inline = true,
  children,
  disabled = false,
  color = null,
  component = 'div', //eslint-disable-line
  sx = null,
  onClick = () => null,
  isFunky = false,
  variant = 'body1',
  ...props
}: {
  path?: string | UrlObject;
  target?: '_self' | '_blank' | '_parent' | '_top';
  underline?: 'hover' | 'none';
  inline?: boolean;
  children: ReactNode;
  disabled?: boolean;
  color?: string;
  component?: ElementType<any>; //eslint-disable-line
  sx?: object;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isFunky?: boolean;
  variant?: 'body1' | 'body2' | 'subtitle1' | 'subtitle2' | 'caption';
  props?: ReactPropTypes;
}) {
  const theme = useTheme();
  sx;

  let underlineThickness = 1;

  // if variant is one of subtitle1, subtitle2, set the underline thickness to 2
  if (variant === 'subtitle1' || variant === 'subtitle2') {
    underlineThickness = 2;
  }

  // if color is a string such as primary.main, convert it to a hex value by using the theme
  if (color !== null && color.includes('.')) {
    color = theme.palette[color.split('.')[0]][color.split('.')[1]];
  } else if (color === null) {
    color = theme.palette.text.primary;
  }
  const [isHovering, setIsHovering] = useState(false);
  const typography = theme.typography;

  // If variant subtitle1, subtitle2, or body1, set the bottom to -3px
  const bottom =
    variant === 'subtitle1' ||
    variant === 'subtitle2' ||
    variant === 'body1'
      ? '-3px'
      : '0px';

  return (
    <Box
      onMouseEnter={() => setIsHovering(disabled ? false : true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        display: inline ? 'inline' : null,
        ...(!isFunky && {
          cursor: 'pointer',
          ...(underline === 'hover' && {
            position: 'relative',
            '&:before': {
              borderRadius: Math.ceil(underlineThickness / 2) + 'px',
              content: "''",
              position: 'absolute',
              width: '0',
              height: underlineThickness + 'px',
              bottom: bottom,
              transform: 'translate(0%,0%)',
              background: `linear-gradient(45deg, ${color}, ${color})`,
              visibility: 'hidden',
              transition: 'all 0.2s ease-in-out'
            },
            ...(!disabled && {
              '&:hover:before': {
                visibility: 'visible',
                width: '100%'
              }
            }),
            transition: 'all 0.2s ease-in-out'
          })
        })
      }}
      // component={component}
      onClick={onClick}
      {...props}
    >
      {path ? (
        <NextLink href={path}>
          <a
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              display: 'inline-block',
              transition: 'all 0.2s ease-in-out',
              color: color,
              ...typography?.[isFunky ? 'subtitle2' : variant],
              ...(isFunky && {
                paddingTop: '18px',
                paddingBottom: '18px',
                paddingLeft: '36px',
                paddingRight: '36px',
                border:
                  '2px solid ' +
                  (isHovering
                    ? theme.palette.primary.main
                    : theme.palette.text.primary),
                borderRadius: 8 + 'px',
                color: isHovering
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
                transform:
                  'translateY(' + (isHovering ? -2 : 0) + 'px)'
              })
            }}
            target={target}
          >
            <p className={variant + (disabled ? ' text-muted' : '')}>
              {children}
            </p>
          </a>
        </NextLink>
      ) : (
        <a
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            transition: 'all 0.2s ease-in-out',
            color: color,
            ...typography?.[isFunky ? 'subtitle2' : variant],
            ...(isFunky && {
              paddingTop: '18px',
              paddingBottom: '18px',
              paddingLeft: '36px',
              paddingRight: '36px',
              border:
                '2px solid ' +
                (isHovering
                  ? theme.palette.primary.main
                  : theme.palette.text.primary),
              borderRadius: 8 + 'px',
              color: isHovering
                ? theme.palette.primary.main
                : theme.palette.text.primary,
              transform: 'translateY(' + (isHovering ? -2 : 0) + 'px)'
            })
          }}
          target={target}
        >
          <p className={variant + (disabled ? ' text-muted' : '')}>
            {children}
          </p>
        </a>
      )}
    </Box>
  );
}
